import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Card, CardBody, Heading, Text, TextArea, Flex, Input, Button } from '@thenexlabs/uikit';
import { useTranslation } from 'contexts/Localization';

const NixContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Box = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  margin: auto;
  margin-top: 200px;
  margin-bottom: 100px;
  border: 1px solid lime;
  perspective: 1000px;
  transform-style: preserve-3d;
  transform-origin: center;
  transition: transform .2s ease-out;
  transform: rotateZ(135deg) rotateY(15deg) rotateX(15deg);

  /* Add this for the metallic/silver shine effect */
  background: black;//linear-gradient(-45deg, #c0c0c0, #808080, #c0c0c0, #808080);
  background-size: 500% 500%;
  animation: gradient 5s ease infinite;

  @media (max-width: 1200px) {
    width: 100px;
    height: 100px;
    margin-top: 100px;
  }
`;

const Eye = styled.div`
  position: absolute;
  top: 30%;
  left: 30%;
  width: 40%;
  height: 40%;
  border: 1px solid lime;
  background-color: white;
  border-radius: 50%;
  transform: rotateZ(45deg);

  /* Add this for the neon green glow effect */
  box-shadow: 0 0 10px #00ff00, 
              0 0 20px #00ff00, 
              0 0 30px #00ff00, 
              0 0 50px #00ff00;

  cursor: pointer;
`;


const Side = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  transform-origin: left center;
  transform: rotateY(90deg);
  border: 1px solid lime;
  border-bottom-right-radius: 70%;
  border-top-right-radius: 10%;

  /* Add this for the metallic/silver shine effect */
  background: black;//linear-gradient(-45deg, #c0c0c0, #808080, #c0c0c0, #808080);
  background-size: 500% 500%;
  animation: gradient 5s ease infinite;

  @media (max-width: 1200px) {
    width: 100px;
    height: 100px;
  }
`;


const Side2 = styled.div`
  position: absolute;
  top: 0;
  left: 299px;
  width: 300px;
  height: 300px;
  background-color: #999;
  transform-origin: left center;
  transform: rotateY(90deg);
  border: 1px solid lime;
  border-top-right-radius: 70%;
  border-bottom-right-radius: 10%;

  /* Add this for the metallic/silver shine effect */
  background: black;//linear-gradient(-45deg, #c0c0c0, #808080, #c0c0c0, #808080);
  background-size: 500% 500%;
  animation: gradient 5s ease infinite;

  @media (max-width: 1200px) {
    width: 100px;
    height: 100px;
    left: 99px;
  }
`;

const Top = styled.div`
  position:absolute;
  top: 299px;
  left: 0;
  width: 300px;
  height: 300px;
  background-color:#999;
  transform-origin:center top;
  transform:rotateX(-90deg);
  border: 1px solid lime;
  border-bottom-left-radius: 70%;
  border-bottom-right-radius: 10%;

  /* Add this for the metallic/silver shine effect */
  background: black;//linear-gradient(-45deg, #c0c0c0, #808080, #c0c0c0, #808080);
  background-size: 500% 500%;
  animation: gradient 5s ease infinite;

  @media (max-width: 1200px) {
    width: 100px;
    height: 100px;
    top: 99px;
  }
`;

const Bottom = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  background-color:#999;
  transform-origin:center top;
  transform:rotateX(-90deg);
  border: 1px solid lime;
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 70%;

  /* Add this for the metallic/silver shine effect */
  background: black;//linear-gradient(-45deg, #c0c0c0, #808080, #c0c0c0, #808080);
  background-size: 500% 500%;
  animation: gradient 5s ease infinite;

  @media (max-width: 1200px) {
    width: 100px;
    height: 100px;
  }
`;

const Hair = styled.div`
  position: absolute;
  top: -200px;
  left: -50px;
  width: 300px;
  height: 550px;
  background-color: lime;
  transform-origin: left center;
  transform: rotateY(90deg);
  border-top-left-radius: 50%;
  border-top-right-radius: 10%;
  border-bottom-right-radius: 50%;
  display: none;

  /* Add this for the neon green glow effect */
  box-shadow: 0 0 5px #00ff00, 
              0 0 10px #00ff00, 
              0 0 15px #00ff00, 
              0 0 25px #00ff00;

  @media (max-width: 1200px) {
    width: 110px;
    height: 160px;
    top: -50px;
    left: -10px;
  }
`;

const Hair2 = styled.div`
  position: absolute;
  top: 345px;
  left: 0;
  width: 500px;
  height: 300px;
  background-color: lime;
  transform-origin: center top;
  transform: rotateX(-90deg);
  border-top-right-radius: 50%;
  border-bottom-right-radius: 10%;
  border-bottom-left-radius: 10%;
  display: none;

  /* Add this for the neon green glow effect */
  box-shadow: 0 0 5px #00ff00, 
              0 0 10px #00ff00, 
              0 0 15px #00ff00, 
              0 0 25px #00ff00;

  @media (max-width: 1200px) {
    width: 140px;
    height: 100px;
    top: 109px;
  }
`;


const Nix = (props) => {
  const { t } = useTranslation();
  const boxRef = useRef(null);

  const [isNixAwake, setIsNixAwake] = useState(false);
  const [eyeHeight, setEyeHeight] = useState('40%');
  const [nixClickCounter, setNixClickCounter] = useState(0);
  const [blinkTimeout, setBlinkTimeout] = useState(null);

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const { left, top, width: offsetWidth, height: offsetHeight } = boxRef.current.getBoundingClientRect();
    // Calculate the distance of the cursor from the center of the box
    const x = clientX - (left + offsetWidth / 2);
    const y = clientY - (top + offsetHeight / 2);
    // Ensure x and y are within the range [-width/2, width/2] and [-height/2, height/2]
    // Calculate the distance from the center
    const distance = Math.sqrt(x * x + y * y);
    let boundedX, boundedY;
    if (distance <= offsetWidth / 2) {
      // Use the old method if the cursor is within 10px of the center
      boundedX = Math.max(Math.min(x, offsetWidth * 2 / 3), -offsetWidth * 2 / 3);
      boundedY = Math.max(Math.min(y, offsetHeight * 2 / 3), -offsetHeight * 2 / 3);
    } else {
      // Use the new method otherwise
      const angle = Math.atan2(y, x);
      boundedX = Math.cos(angle) * (offsetWidth * 2 / 3);
      boundedY = Math.sin(angle) * (offsetHeight * 2 / 3);
    }
    // Normalize the distances to [-0.5, 0.5] and invert y for correct rotation direction
    const normX = (boundedX / offsetWidth);
    const normY = -(boundedY / offsetHeight);
    // Convert normalized distances to degrees, with max rotation of -90 degrees
    const rotX = normY * 90;
    const rotY = normX * 90;

    if(!isNixAwake){
      // boxRef.current.style.display = 'block'
      setIsNixAwake(true)
    }
    boxRef.current.style.transform = `rotateY(${rotY}deg) rotateX(${rotX}deg) rotateZ(45deg) scaleY(-1)`;
  };

  const handleNixClick = () => {
    // Reset the blink timer
    setEyeHeight('1%'); // Close the eye
    clearTimeout(blinkTimeout); // Clear the existing blink timeout
    setBlinkTimeout(
      setTimeout(() => setEyeHeight('40%'), 200) // Open the eye after 200ms
    );
    setNixClickCounter(nixClickCounter + 1);
    if(nixClickCounter > 4){
      setTimeout(() => setNixClickCounter(0), 4000);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    
    // Blinking animation
    // let blinkTimeout = null; add back if remove state variable
    const blinkInterval = setInterval(() => {
      setEyeHeight('1%'); // Close eye
      // blinkTimeout = setTimeout(() => setEyeHeight('40%'), 300); // Open eye after 300ms
      setBlinkTimeout(setTimeout(() => setEyeHeight('40%'), 300))
    }, 5000); // Run every 5 seconds

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      clearInterval(blinkInterval); // Clear interval on unmount
      clearTimeout(blinkTimeout); // Clear timeout on unmount
    };
  }, [blinkTimeout]);


  return (
    <>
    {
      <NixContainer>
        <Box 
          ref={boxRef}
          style={
            props.size==='sm'?{
              width: '100px',
              height: '100px',
              marginTop: '100px',
              marginBottom: '180px',
              border: `1px solid ${nixClickCounter>5?'red':'lime'}`
            }
            :
            {
              border: `1px solid ${nixClickCounter>5?'red':'lime'}`
            }
          }
        >
          <Eye 
            onClick={handleNixClick}
            style={
              isNixAwake?{
                height: eyeHeight,
                top: eyeHeight<'20%'?'55%':'30%',
                left: eyeHeight<'20%'?'25%':'30%',
                backgroundColor: `${nixClickCounter>5?'red':'white'}`,
                border: `1px solid ${nixClickCounter>5?'red':'lime'}`,
                boxShadow: `0 0 5px ${nixClickCounter>5?'red':'#00ff00'}, 
                            0 0 10px ${nixClickCounter>5?'red':'#00ff00'}, 
                            0 0 15px ${nixClickCounter>5?'red':'#00ff00'}, 
                            0 0 25px ${nixClickCounter>5?'red':'#00ff00'}`
              }
              :
              {
                height: '1%',
                top: '55%',
                left: '25%',
              }
            }
          />
          <Side 
            style={
              props.size==='sm'?{
                width: '100px',
                height: '100px',
                border: `1px solid ${nixClickCounter>5?'red':'lime'}`
              }
              :
              {
                border: `1px solid ${nixClickCounter>5?'red':'lime'}`
              }
            }
          />
          <Side2
            style={
              props.size==='sm'?{
                width: '100px',
                height: '100px',
                left: '99px',
                border: `1px solid ${nixClickCounter>5?'red':'lime'}`
              }
              :
              {
                border: `1px solid ${nixClickCounter>5?'red':'lime'}`
              }
            }
          />
          <Top 
            style={
              props.size==='sm'?{
                width: '100px',
                height: '100px',
                top: '99px',
                border: `1px solid ${nixClickCounter>5?'red':'lime'}`
              }
              :
              {
                border: `1px solid ${nixClickCounter>5?'red':'lime'}`
              }
            }
          />
          <Bottom 
            style={
              props.size==='sm'?{
                width: '100px',
                height: '100px',
                border: `1px solid ${nixClickCounter>5?'red':'lime'}`
              }
              :
              {
                border: `1px solid ${nixClickCounter>5?'red':'lime'}`
              }
            }
          />
          <Hair 
            style={
              isNixAwake?{
                display: 'block',
                width: props.size==='sm'?'110px':'',
                height: props.size==='sm'?'160px':'',
                top: props.size==='sm'?'-50px':'',
                left: props.size==='sm'?'-10px':'',
                backgroundColor: `${nixClickCounter>5?'red':'lime'}`,
                boxShadow: `0 0 5px ${nixClickCounter>5?'red':'#00ff00'}, 
                            0 0 10px ${nixClickCounter>5?'red':'#00ff00'}, 
                            0 0 15px ${nixClickCounter>5?'red':'#00ff00'}, 
                            0 0 25px ${nixClickCounter>5?'red':'#00ff00'}`
              }
              :
              {
                backgroundColor: `${nixClickCounter>5?'red':'lime'}`
              }
            }
          />
          <Hair2 
            style={
              isNixAwake?{
                display: 'block',
                width: props.size==='sm'?'140px':'',
                height: props.size==='sm'?'100px':'',
                top: props.size==='sm'?'109px':'',
                backgroundColor: `${nixClickCounter>5?'red':'lime'}`,
                boxShadow: `0 0 5px ${nixClickCounter>5?'red':'#00ff00'}, 
                            0 0 10px ${nixClickCounter>5?'red':'#00ff00'}, 
                            0 0 15px ${nixClickCounter>5?'red':'#00ff00'}, 
                            0 0 25px ${nixClickCounter>5?'red':'#00ff00'}`
              }
              :
              {
                backgroundColor: `${nixClickCounter>5?'red':'lime'}`
              }
            }
          />
        </Box>
      </NixContainer>
    }
    </>
  );
};


export default Nix;

