import React from 'react';

interface YouTubeEmbedProps {
  url: string;
  height?: any;
}

export const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ url, height }) => {
  try {
    const videoId = new URL(url).searchParams.get('v');
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
      <iframe
        width="100%"
        height={height}
        src={embedUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  } catch (error) {
    return <div>Invalid URL</div>;
  }
};

export default YouTubeEmbed;