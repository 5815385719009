import React, { useState, useEffect, useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { Flex, Heading, Button, Text, Box, ArrowBackIcon, ArrowForwardIcon } from '@thenexlabs/uikit'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'contexts/Localization'
import ConnectWalletButton from 'components/ConnectWalletButton'
import useTheme from 'hooks/useTheme'
import { ThemeContext } from 'styled-components';
import { SlideSvgDark, SlideSvgLight } from './SlideSvg'
import CompositeImage, { getSrcSet, CompositeImageProps } from './CompositeImage'
import { usePriceXpBusd } from 'state/farms/hooks'
import { useXpData } from 'state/xp/hooks'
import { numberWithCommas } from 'utils'
import YouTubeEmbed from 'components/YouTubeEmbed'
import BounceDiv from 'components/BounceDiv'
import Nix from 'components/Nix';
import Image from 'next/image'


const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const fading = () => keyframes`
  from {
    opacity: 0.9;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 0.9;
  }
`

const movement = () => keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 3300px; /////////////////// EDIT HERE
  left: 0px;
  overflow: hidden;


  @media (max-width: 650px) {
    bottom: 3000px; /////////////////// EDIT HERE
  }
  @media (max-width: 450px) {
    bottom: 3750px; /////////////////// EDIT HERE
  }
`

interface InnerWrapperProps {
  overlayColor: string;
}

const InnerWrapper = styled.div<InnerWrapperProps>`
  position: absolute;
  bottom: 0px;
  left: -50%;
  background-color: rgba(20 30 40 0.4);
  background-image: linear-gradient(to top, transparent 50%, ${props => props.overlayColor}), linear-gradient(90deg, transparent 0%, transparent 85%, #17FF12 90%, transparent 95%),  linear-gradient(0deg, transparent 0%, transparent 75%, #17FF12 90%, transparent 95%);
  background-size: cover, 35px 16px, 35px 16px;
  width: 200vw;
  height: 400px;
  transform: translate3D(0, 0, 0) perspective(350px) rotateX(45deg);
  perspective-origin: top;
  will-change: transform;
  animation-name: ${movement};
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`


const InfoBgWrapper = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
`

const InfoBg = styled.div`
  position: absolute;
  width: 200vw;
  height: 3300px; /////////////////// EDIT HERE
  bottom: 0px;
  left: calc(-50% - 12px);


  background-color: rgba(20 30 40 0.4);
  background-image: linear-gradient(90deg, transparent 0%, transparent 85%, #17FF12 95%, transparent 95%), linear-gradient(0deg, transparent 0%, transparent 75%, #17FF12 90%, transparent 95%);
  background-size: 53px 53px;

  border-top: 2px solid #17FF12;
  transform: translate3D(0, 0, 0) perspective(150px) rotateX(0deg) ;
  perspective-origin: top;

  animation-name: ${movement};
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  & :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, transparent 100%, black), linear-gradient(to bottom, transparent 50%, black);
    // background: linear-gradient(to top, transparent 20%, black), linear-gradient(to bottom, transparent 20%, black);
  }

  @media (max-width: 650px) {
    height: 3000px; /////////////////// EDIT HERE
  }
  @media (max-width: 450px) {
    height: 3750px; /////////////////// EDIT HERE
  }
`

const StarsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  & :nth-child(2) {
    animation: ${fading} 2s ease-in-out infinite;
    animation-delay: 1s;
  }

  & :nth-child(3) {
    animation: ${fading} 5s ease-in-out infinite;
    animation-delay: 0.66s;
  }

  & :nth-child(4) {
    animation: ${fading} 2.5s ease-in-out infinite;
    animation-delay: 0.33s;
  }
`

const TagWrapper = styled.div`
  position: absolute;
  height: 50px;
  width: 200px;
  bottom: 0;
  right: 0;
`

const imagePath = '/images/home/lunar-bunny/'
const imageSrc = 'bunny'

const starsImage: CompositeImageProps = {
  path: '/images/home/lunar-bunny/',
  attributes: [
    { src: 'star-l', alt: '3D Star' },
    { src: 'star-r', alt: '3D Star' },
    { src: 'star-top-r', alt: '3D Star' },
  ],
}

const XpWrapper = styled.div`
  box-shadow: 0px 0px 20px #17FF12;
  border-radius: 50px;
  text-align: center;
  padding: 30px;
  margin: 0 auto;
  display: inline-block;
  width: fit-content;
  block-size: fit-content;
`

const XpBanner = styled.div`
  box-shadow: 0px 0px 20px #17FF12;
  text-align: center;
  padding: 30px;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`

const NexIsAnimateContainer = styled.div`
  
`

const NexIsAnimateWrapper = styled.div`
  transition: opacity 1s linear;
  opacity: 1;
`

const Parallax = styled.div`
  display: flex;
  flex-direction: row;
  animation: ${keyframes`
    0% { transform: translateX(100vw); }
    100% { transform: translateX(-130vw); } // set based off length of array in the future
  `} 10s linear infinite;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  gap: 2vw;
  z-index: 1000;

  @media (max-width: 850px) {
    animation: ${keyframes`
      0% { transform: translateX(100vw); }
      100% { transform: translateX(-300vw); } // set based off length of array in the future
    `} 5s linear infinite;
  }

  @media (max-width: 450px) {
    animation: ${keyframes`
      0% { transform: translateX(100vw); }
      100% { transform: translateX(-400vw); } // set based off length of array in the future
    `} 5s linear infinite;
  }
`;

const nexUmbrella = [
  {
    header: "Cyber Security",
    description: "Compliancy Reports, Audits, 24/7 Defensive Monitoring, AI, Security Assessments, Strategy, Training"
  },
  {
    header: "Research & Development",
    description: "We love building with the latest technologies in gaming, web, and network security"
  },
  {
    header: "Web4",
    description: "The Symbiotic Web, WebXR, AI Network Management, AI Automation, AI Security"
  },
  {
    header: "Gaming",
    description: "Discover new games built with the latest technologies in new experiences"
  },
];

const automations = [
  "Network Monitoring",
  "Vulnerability Reporting",
  "Threat Intelligence & Response",
  "Data Protection & Compliancy",
  "User Training & Awareness",
  "Endpoint & Cloud Security",
  "Log Data Analysis",
  "IT Hygiene"
];

const complianceStandards = [
  "SOC2",
  "PCI DSS",
  "HIPAA",
  "PIPEDA",
  "NIST 800-53",
  "TSC",
  "GDPR"
];

const nixChatExamples = [
  {
    id: 1,
    displayDuration: 13000,
    subheader: "Real-Time System Overviews"
  },
  {
    id: 2,
    displayDuration: 8000,
    subheader: "Scheduled Report Generations"
  },
  {
    id: 3,
    displayDuration: 7500,
    subheader: "Customized Training Sessions"
  },
  {
    id: 4,
    displayDuration: 8000,
    subheader: "Incident Response Information"
  },
];

const services = [
  "Cyber Security Audit",
  "Comprehensive Cyber Security Assessment",
  "Customized Cyber Security Strategy",
  "Implementation of Cyber Security Measures",
  "Ongoing Monitoring and Support",
  "Regular Updates and Training"
];

const devices = [
  "Windows",
  "macOS",
  "Linux",
  "Solaris",
  "AIX",
  "other operating systems"
];

const affiliated_brands = [
  {
    name: "Microsoft",
    logoUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/512px-Microsoft_logo.svg.png?20210729021049"
  },
  {
    name: "Wazuh",
    logoUrl: "https://asset.brandfetch.io/idGBqJSmMa/idGy878o9O.jpeg"
  },
  {
    name: "Wazuh SC Award Winners 2023",
    logoUrl: "https://wazuh.com/wp-content/themes/wazuh-v3/assets/images/siem-award-badge.png"
  },
  {
    name: "OpenVPN",
    logoUrl: "https://seeklogo.com/images/O/openvpn-logo-1F15BD0826-seeklogo.com.png" 
  }
];

const lightPulse = keyframes`
  0% {
    text-shadow: 0 0 0px #00ff00, 0 0 0px #00ff00, 0 0 00px #00ff00, 0 0 00px #00ff00;
  }
  100% {
    text-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 40px #00ff00, 0 0 80px #00ff00;
  }
`;

const NeonText = styled.span`
  // color: #00ff00;
  text-shadow: 0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 40px #00ff00;
  animation: ${lightPulse} 2s infinite alternate;
`;

const Hero = () => {
  const [nexUmbrellaIndex, setNexUmbrellaIndex] = useState(nexUmbrella.length-1);
  const [nixChatExampleIndex, setNixChatExampleIndex] = useState(0);
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { isDark } = useTheme()
  const theme = useContext(ThemeContext);
  const bgColor = isDark ? theme.colors.backgroundAlt : '#e9fbfb';

  const {xpCap, totalLockedXp, currentSupply} = useXpData();
  const xpPriceUsd = usePriceXpBusd()


  const capXp = Number(xpCap.div(10**18).toFixed())
  const tlXp = Number(totalLockedXp.div(10**18).toFixed(3))
  const supply = numberWithCommas(Number(currentSupply.div(10**18).toFixed(0)))


  const capXpV = numberWithCommas(capXp)
  const tvlXp = numberWithCommas((tlXp*Number(xpPriceUsd)).toFixed())


  useEffect(() => {
    const func = () => {
      let element = document.getElementById('animate');
      element.style.opacity = '1'; // show
      setTimeout(() => {
        element.style.opacity = '0'; // hide after changing text
      }, 3000); // wait for [interval (4) - animation duration (1)] seconds (same as transition duration)
      setNexUmbrellaIndex((prevIndex) => {
        let newIndex = (prevIndex + 1) % nexUmbrella.length;
        return newIndex;
      });
    }
    const interval = setInterval(func, 4000); // change every 4 seconds
    func()
    return () => clearInterval(interval); // cleanup on unmount
  }, []);

  useEffect(() => {
    const func = () => {
      setNixChatExampleIndex((prevIndex) => {
        let newIndex = (prevIndex + 1) % nixChatExamples.length;
        return newIndex;
      });
    }
    const interval = setInterval(func, nixChatExamples[nixChatExampleIndex].displayDuration); // change based on displayDuration
    return () => clearInterval(interval); // cleanup on unmount
  }, [nixChatExampleIndex]);

  return (
    <>
      <BgWrapper>
        <InnerWrapper overlayColor={bgColor}>
          {/*isDark ? <SlideSvgDark width="100%" /> : <SlideSvgLight width="100%" />*/}
        </InnerWrapper>
      </BgWrapper>
      <InfoBgWrapper>
        <InfoBg>
          <div/>
        </InfoBg>
      </InfoBgWrapper>
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems={['center']}
        justifyContent="center"
        mt={[account ? '280px' : '50px', null, 0]}
        id="homepage-hero"
      >
        <Flex
          flex="1"
          flexDirection="column"
          mt="50px"
          style={{zIndex: 1000, textAlign: 'center'}}
        >
          <Heading scale="xxl" mb="24px">
            <NeonText>{t('Autonomous')}</NeonText> {t('cyber security for your business or home.')}
          </Heading>
          <Text mb="24px">
            Effortlessly secure and manage your network with our automated solution. Our platform integrates AI-driven security, 3D force graph visualization, file integrity monitoring, vulnerability detection, data encryption, access control, and regulatory compliance. Achieve a fully operational SIEM + XDR SOC in just 10 minutes for under $5 per endpoint/computer, without needing technical expertise. All your data is securely stored in your own private, customized cloud database.            
            <NeonText>
              <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                margin: '20px 0',
              }}>
                <span style={{
                  fontSize: '1.2em',
                  margin: '0 10px',
                }}>
                  Our customers report average <span style={{fontSize: '2.2em'}}>💵</span> & <span style={{fontSize: '2.2em'}}>🕒</span> savings of up to 94%!
                </span>
              </div>
            </NeonText>
          </Text>
          
          <Flex
            flexDirection={["column","column","column","row"]}
            mb={["0px","0px","0px","200px","100px"]}
            style={{justifyContent: 'center'}}
          >
            <div>
              <NextLinkFromReactRouter target="_blank" to="https://nixguard.thenex.world">
                <Button variant={'primary'} mt="5px" mr="8px">{t('Try NixGuard FREE')}</Button>
              </NextLinkFromReactRouter> 
            </div>
            {/*!account && <ConnectWalletButton mr="8px" />*/}
            {/*<NextLinkFromReactRouter to="/swap">
              <Button variant={!account ? 'secondary' : 'primary'}>{t('Trade Now')}</Button>
            </NextLinkFromReactRouter>*/}
            {/*<div>  
              <NextLinkFromReactRouter target="_blank" to="/security/services">
                <Button variant={'secondary'} mt="5px" mr="8px">{t('Security Services We Offer')}</Button>
              </NextLinkFromReactRouter>
            </div>
            <div>
              <NextLinkFromReactRouter target="_blank" to="/web4/about">
                <Button mt="5px" mr="8px" variant={!account ? 'secondary' : 'primary'}>{t('What is Web4?')}</Button>
              </NextLinkFromReactRouter>        
            </div>
            <div>
              <NextLinkFromReactRouter target="_blank" to="https://hub.thenex.world/#world-hub">
                <Button variant={'secondary'} mt="5px" mr="8px">{t('Enter The NEX')}</Button>
              </NextLinkFromReactRouter>
            </div>*/}
          </Flex>          
        </Flex>
      </Flex>

      <Nix size="sm"/>
      <Flex
        flex="1"
        flexDirection="column"
        style={{
          maxWidth: '800px', margin: 'auto', marginTop: '-150px',
          boxShadow: '0px 0px 20px #17FF12', borderRadius: '5px', overflow: 'hidden'
        }}
      > 
        <YouTubeEmbed height='400px' url="https://www.youtube.com/watch?v=JoSF0-4EyQ0"/>
      </Flex>

      <Flex
        flex="1"
        flexDirection="column"
        mt="200px"
        mb={['100px', null, null, '300px']}
        style={{zIndex: 1000}}
      >
        <XpWrapper style={{background: isDark?'black':'white'}}>
          <Heading mb={25} scale="xl">
            NEX Level Cyber Security
          </Heading>
          <Heading mb={25}  scale="md">
            Extensive cyber security automation to help you protect your networks, for less than you spend on groceries.
          </Heading>
          <div style={{overflow: 'hidden', marginBottom: '10px'}}>
            <BounceDiv scale="xl" items={automations} interval={3500} />
          </div>
          <Heading mb={25} scale="md">
            Automatically configure a wide array of compliancy standards:
          </Heading>
          <div style={{overflow: 'hidden', marginBottom: '10px'}}>
            <BounceDiv scale="xl" items={complianceStandards} interval={2500} />
          </div>
          <NextLinkFromReactRouter target="_blank" to="/security/subscribe"/*?free=true"*/>
            <Button scale="md" mb="8px" mr="8px" variant={'primary'}>{t('Subscribe')}</Button>
          </NextLinkFromReactRouter>
          <NextLinkFromReactRouter target="_blank" to="/nix">
            <Button scale="md" mb="8px" mr="8px" variant={'secondary'}>{t('Security AI')}</Button>
          </NextLinkFromReactRouter>
          <NextLinkFromReactRouter target="_blank" to="/web4/network-dashboard/about">
            <Button scale="md" mb="8px" mr="8px" variant={'primary'}>{t('Security Automation?')}</Button>
          </NextLinkFromReactRouter>
        </XpWrapper>
        <XpBanner style={{marginTop: '200px', background: isDark?'black':'white'}}>
         <Flex
            flex="1"
            flexDirection={["column", "column", "column", "row"]}
            mt="45px"
            mb="20px"
            style={{
              zIndex: 1000, 
              padding: '40px',
              margin: 'auto',
              alignItems: 'flex-start',
              justifyContent: 'space-between'
            }}
          >
            <Box style={{textAlign: 'left', margin: 'auto'}}>
              <Heading mb={50} scale="xl">
                Chat 24/7
              </Heading>
              <Heading 
                scale="lg"
                color="secondary"
              >
                {nixChatExamples[nixChatExampleIndex].subheader}
              </Heading>
            </Box>
            <Flex style={{margin: 'auto'}} flexDirection="column" alignItems="flex-end">
              <Flex style={{marginBottom: '5px'}} flexDirection="row" alignItems="flex-end">
                <Button 
                  style={{padding: '5px', height: '30px'}}
                  variant={'secondary'}
                  onClick={() => setNixChatExampleIndex((nixChatExampleIndex - 1 + nixChatExamples.length) % nixChatExamples.length)}
                >
                  <ArrowBackIcon/>
                </Button>
                <Button 
                  style={{padding: '5px', height: '30px'}}
                  variant={'secondary'}
                  onClick={() => setNixChatExampleIndex((nixChatExampleIndex + 1) % nixChatExamples.length)}
                >
                  <ArrowForwardIcon/>
                </Button>
              </Flex>
              <NextLinkFromReactRouter target="_blank" to="/nix/about">
                <Image 
                  alt={`Chat Example ${nixChatExampleIndex}`}
                  src={`/images/nix-chat-examples/Nix-Chat-Example-${nixChatExamples[nixChatExampleIndex].id}.gif`} 
                  priority 
                  unoptimized={true}
                  width={324}
                  height={571}
                  style={{borderRadius: '15px'}}
                />
              </NextLinkFromReactRouter>
            </Flex>
          </Flex>
        </XpBanner>
        {/*<XpWrapper style={{marginTop: '200px', background: isDark?'black':'white'}}>
          <Heading mb={25} color="secondary" scale="xl">
            IOT Protection
          </Heading>
          <Flex
            flex="1"
            flexDirection={["column","column","column","row"]}
            mt="25px"
            mb="25px"
            style={{
              zIndex: 1000, 
              padding: '10px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {devices.map((device, index) => (
              <div style={(index>0)?{
                marginLeft: "7px", 
                paddingLeft: '7px'
              }:{}} key={index}>
                <Heading>
                  {index>0?'| ':''}{device}
                </Heading>
              </div>
            ))}
          </Flex>
          <NextLinkFromReactRouter target="_blank" to="https://d5mlsmm4ujx.typeform.com/to/b0U2avC8">
            <Button scale="md" mb="8px" mr="8px" variant={'primary'}>{t('Book a Demo')}</Button>
          </NextLinkFromReactRouter>
          <NextLinkFromReactRouter target="_blank" to="https://d5mlsmm4ujx.typeform.com/to/h8FX3Jvo">
            <Button scale="md" mb="8px" mr="8px" variant={'secondary'}>{t('FREE 5 Minute Security Assessment')}</Button>
          </NextLinkFromReactRouter>
          <NextLinkFromReactRouter target="_blank" to="/security/services">
            <Button scale="sm" mb="8px" mr="8px" variant={'primary'}>{t('Learn More')}</Button>
          </NextLinkFromReactRouter>
        </XpWrapper>*/}
        <XpBanner style={{marginTop: '200px', marginBottom: '100px', background: isDark?'black':'white'}}>
          <Parallax style={{ height: '300px' }}>
            {affiliated_brands.map((partner, index) => (
              <div style={{ width: `${100 / affiliated_brands.length}vw`, minWidth: '200px', marginRight: `${100 / affiliated_brands.length}vw` }} key={index}>
                <img src={partner.logoUrl} alt={partner.name} style={{ width: '100%', height: 'auto' }} />
              </div>
            ))}
          </Parallax>
        </XpBanner>
        {/*<XpBanner style={{marginTop: '200px', background: isDark?'black':'white'}}>
          <Heading mb={25} style={{textDecoration: 'underline'}} color="secondary" scale="xl">
            Cyber Security Services
          </Heading>
          <Flex
            flex="1"
            flexDirection={["column","column","column","row"]}
            mt="45px"
            mb="20px"
            style={{
              zIndex: 1000, 
              padding: '10px'
            }}
          >
            {services.map((service, index) => (
              <div style={(index>0)?{marginLeft: "10px"}:{}} key={index}>
                <Text mb={25}>
                  {service}
                </Text>
              </div>
            ))}
          </Flex>
          <NextLinkFromReactRouter target="_blank" to="https://d5mlsmm4ujx.typeform.com/to/h8FX3Jvo">
            <Button scale="md" mb="8px" mr="8px" variant={'primary'}>{t('FREE 5 Minute Security Assessment')}</Button>
          </NextLinkFromReactRouter>
          <NextLinkFromReactRouter target="_blank" to="/contact">
            <Button scale="md" mb="8px" mr="8px" variant={'secondary'}>{t('Contact Us')}</Button>
          </NextLinkFromReactRouter>
          <NextLinkFromReactRouter target="_blank" to="/security/services">
            <Button scale="sm" mb="8px" mr="8px" variant={'primary'}>{t('Learn More')}</Button>
          </NextLinkFromReactRouter>
        </XpBanner>*/}
        <Nix size="md"/>
        <XpWrapper style={{marginTop: '-100px', zIndex: 9999, background: isDark?'black':'white'}}>
          <NexIsAnimateContainer>
            <NexIsAnimateWrapper id="animate">
              <Heading style={{textDecoration: 'underline', fontSize: '17px'}} color="secondary" mb="5px">
                {t(nexUmbrella[nexUmbrellaIndex].header)}
              </Heading>
              <Text mb="24px">
                {t(nexUmbrella[nexUmbrellaIndex].description)}
              </Text>
            </NexIsAnimateWrapper>
          </NexIsAnimateContainer>
          <Heading mb={25} scale="xl">
            Some of Our Research
          </Heading>
          <NextLinkFromReactRouter target="_blank" to="/documents/The_NEX_Network_Whitepaper.pdf">
            <Button scale="sm">{t('A Web4 Network Design')}</Button>
          </NextLinkFromReactRouter>
        </XpWrapper>
        {/*<XpWrapper style={{margin: '200px auto'}}>
          <Heading style={{textDecoration: 'underline'}} color="secondary" scale="lg">
            XP Coin
          </Heading>
          <Heading scale="md" mt="24px">
            TVL: ${tvlXp}
          </Heading>
          <Heading scale="md" mt="24px">
            Current Supply: {supply} XP
          </Heading>
          <Heading scale="md" mt="24px">
            Supply Cap: {capXpV} XP
          </Heading>
        </XpWrapper>*/}
      </Flex>
    </>
  )
}

export default Hero
