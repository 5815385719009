import React, { useState, useEffect } from 'react';
import { Heading, HeadingProps } from '@thenexlabs/uikit';
import styled, { keyframes } from 'styled-components';


type BouncingDivProps = HeadingProps & {
  interval: number;
};

const bounce = keyframes`
  0% { transform: translateY(-100%); }
  25% { transform: translateY(20%); }
  35% { transform: translateY(0); }
  50% { transform: translateY(0); }
  100% { transform: translateY(130%); }
`;

const BouncingDiv = styled(Heading)<BouncingDivProps>`
  animation: ${bounce} ${props => props.interval}ms linear infinite;
  overflow: hidden;
  padding-bottom: 20px;
`;

const BounceDiv = ({ items, interval, scale }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, interval);

    return () => clearInterval(intervalId); // cleanup on unmount
  }, [items, interval]);

  return <BouncingDiv scale={scale} interval={interval}>{items[index]}</BouncingDiv>;
};

export default BounceDiv;